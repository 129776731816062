import React from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Error500MobileIllustration } from '@toasttab/buffet-pui-illustrations'
import { ToastBrand } from '@toasttab/buffet-pui-toast-logo'
import { useTranslation } from '@local/translations'
import { ErrorCauses } from '../../utils/ErrorCauses'
import { handleBackAction } from '../../utils/handleBackAction'

export const ErrorContent = ({ errorKey }: { errorKey: ErrorCauses }) => {
  const { t } = useTranslation()

  const errorTitle: string = t(`login.error.${errorKey}.title`, 'Login error')
  const errorMessage: string = t(
    `login.error.${errorKey}.message`,
    'An error occurred trying to login; please try again.'
  )
  const buttonLabel = t('login.buttonLabel')

  return (
    <div className='flex flex-col justify-center items-center w-96 px-8 pt-12 pb-8'>
      <ToastBrand className='mb-16' size='md' />
      <span
        data-testid='error-content-title'
        className='font-normal text-default type-headline-4 mb-6 text-center'
      >
        {errorTitle}
      </span>
      {/* Split error messages on semicolon so that they continue on a new line*/}
      {errorMessage?.split(/(?<=[;])/).map((frag, index) => (
        <span
          key={index}
          data-testid={`error-content-message-${index}`}
          className='type-default font-normal text-secondary text-center'
        >
          {frag}
        </span>
      ))}
      <Button
        onClick={handleBackAction}
        className='mb-14 mt-8'
        variant={Button.Variant?.secondary}
      >
        {buttonLabel}
      </Button>
      <Error500MobileIllustration />
    </div>
  )
}
