import * as React from 'react'
import { useQueryParams } from '@local/use-query-params'
import { ErrorContent } from '../ErrorContent'
import { ErrorCauses } from '../../utils/ErrorCauses'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { MarketingCarousel } from '@toasttab/buffet-patterns-marketing-carousel'

export function App() {
  const { error = ErrorCauses['default'] } = useQueryParams<{
    error: ErrorCauses
  }>(['error'])

  // Ensure that we can handle both hyphen and underscore separated error keys
  const parsedError = error.replace(/_/g, '-') as ErrorCauses

  return (
    <Layout>
      <ErrorContent errorKey={parsedError} />
    </Layout>
  )
}

const Layout = ({ children }: { children: React.ReactNode }) => (
  <div className='flex h-screen w-screen'>
    <div data-testid='carousel-container' className='w-3/5 hidden md:block'>
      <MarketingCarousel />
    </div>
    <div
      data-testid='content-container'
      className='w-full md:w-2/5 flex flex-col justify-center items-center md:bg-white'
    >
      <CardContainer noPadding>{children}</CardContainer>
    </div>
  </div>
)
