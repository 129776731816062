import { useLocation } from 'react-router-dom'

/**
 * Allows to request specific query parameters from the URL. If one of them
 * isn't found, it will be returned as undefined in the result map.
 * @param params
 * An array of requested query parameters.
 * @param queryParamSource
 * Optional string representing a list of query parameters. Defaults to
 * `window.location.search`.
 * @returns
 * A map of found query parameters.
 */
export function useQueryParams<T extends Record<string, string | number>>(
  params: (keyof T)[],
  queryParamSource?: string
): Partial<T> {
  const queryParams = new URLSearchParams(
    queryParamSource ?? window.location.search
  )
  return params.reduce((acc, curr) => {
    if (queryParams.has(curr as string)) {
      return { ...acc, [curr]: queryParams.get(curr as string) }
    }
    return acc
  }, {})
}

/**
 * Allows to request specific query parameters from the `react-router-dom`. If
 * one of them isn't found, it will be returned as undefined in the result map.
 * @param params
 * An array of requested query parameters.
 * @returns
 * A map of found query parameters.
 */
export function useRouterQueryParams<T extends Record<string, string | number>>(
  params: (keyof T)[]
): Partial<T> {
  const { search } = useLocation()
  return useQueryParams<T>(params, search)
}
