import { banquetSingleSpaReact, BanquetProps } from 'banquet-runtime-modules'
import { ThemeManager } from '@toasttab/banquet-theme-manager'
import { themes } from '@local/themes'
import { AppBootstrap } from './components/App'
import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  cssScope: 'data-le',
  rootComponent: AppBootstrap,
  sentry: {
    publicKey: '36a4130ef6e24fbaae3cf75604117da1@o37442',
    projectId: '4505120185909248',
    releaseVersion: process.env.SPA_NAMED_VERSION || process.env.PKG_VERSION
  },
  theme: new ThemeManager((props: BanquetProps) => {
    if (props.isNextBuffetTheme) {
      return themes.next
    }
    return themes.current
  })
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'spa-login-error'
